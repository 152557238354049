import React, { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Typography,
  Container,
  Button,
  TextField,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Slider,
} from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import { getProductsByItemId, addProductToCart } from "../../utills/utills";
import { useAuth } from "../../context/UserContext";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSnackbar } from "notistack";
import { useItemContext } from "../../context/ItemsContext";

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
}));

const StyledButton = styled(Button)({
  width: "100%",
  backgroundColor: "blueviolet",
  "&:hover": {
    backgroundColor: "blue",
  },
});

const StyledSlider = styled(Slider)({
  width: "100%",
  color: "blueviolet",
  "& .MuiSlider-thumb": {
    "&:hover": {
      boxShadow: "0px 0px 0px 8px rgba(0, 0, 255, 0.16)",
    },
    "&.Mui-focusVisible": {
      boxShadow: "0px 0px 0px 8px rgba(0, 0, 255, 0.16)",
    },
  },
});
const ProductImage = styled("img")({
  width: "100%",
  maxWidth: "500px",
  height: "auto",
  borderRadius: "12px",
  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
});

const DescriptionList = styled("ol")(({ theme }) => ({
  margin: theme.spacing(2, 0),
  paddingLeft: theme.spacing(4),
  "& li": {
    marginBottom: theme.spacing(1),
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
}));

const StyledTable = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
}));

const Product = () => {
  const { id } = useParams();
  const { allItems } = useItemContext();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [duration, setDuration] = useState(1);
  const startDate = dayjs().format("YYYY-MM-DD");
  const [finishDate, setFinishDate] = useState(
    dayjs().add(1, "month").format("YYYY-MM-DD")
  );

  if (!allItems || allItems.length === 0) {
    return (
      <Typography variant="h6" className="mt-8">
        Loading...
      </Typography>
    );
  }

  const product = allItems.find((p) => p.item_id === parseInt(id));

  if (!product) {
    return (
      <Typography variant="h6" className="mt-8">
        Product not found
      </Typography>
    );
  }

  const maxDuration = dayjs(product.finish_date).diff(dayjs(), "month") + 1;

  const handleSliderChange = (event, newValue) => {
    setDuration(newValue);
    setFinishDate(dayjs(startDate).add(newValue, "month").format("YYYY-MM-DD"));
  };

  const handleInputChange = (event) => {
    const value = event.target.value === "" ? "" : Number(event.target.value);
    if (value >= 1 && value <= maxDuration) {
      setDuration(value);
      setFinishDate(dayjs(startDate).add(value, "month").format("YYYY-MM-DD"));
    }
  };

  const calculatePrice = () => {
    return product.price * duration || product.price;
  };

  const handleAddToCart = async () => {
    const price = calculatePrice();
    if (!user) {
      enqueueSnackbar("Please login to add to cart", { variant: "warning" });
      navigate("/loginsignup");
    }
    try {
      const availableProducts = await getProductsByItemId(product.item_id);
      if (availableProducts.length === 0) {
        enqueueSnackbar("No available products for this item.", {
          variant: "error",
        });
        return;
      }
      const selectedProductId = availableProducts[0].product_id;

      const response = await addProductToCart(
        user.user_id,
        selectedProductId,
        startDate,
        finishDate,
        price
      );
      console.log(`Added to cart: ${response.message}`);
      enqueueSnackbar("Added to cart", { variant: "success" });
      window.location.reload();
    } catch (error) {
      console.error("Error adding product to cart:", error.message);
      enqueueSnackbar("Failed to add to cart", { variant: "error" });
    }
  };

  // Filter similar products (for simplicity, we'll assume similar products have the same item_type)
  const similarProducts = allItems
    .filter(
      (p) => p.item_type === product.item_type && p.item_id !== product.item_id
    )
    .slice(0, 4); // Limit to 4 similar products

  return (
    <StyledContainer maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 4,
        }}
      >
        {/* Product Image */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            maxWidth: { xs: "100%", lg: "50%" },
          }}
        >
          <ProductImage src={product.product_image} alt={product.item_name} />
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            {product.description}
          </Typography>
        </Box>

        {/* Product Details */}
        <Box sx={{ flex: 1 }}>
          {/* Title and Stock Status */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "primary.main",
                textAlign: { xs: "center", lg: "left" },
              }}
            >
              {product.item_name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                ml: 3,
                fontWeight: 600,
                color: product.available_products > 0 ? "green" : "red",
              }}
            >
              {product.available_products > 0 ? "In Stock" : "Out of Stock"}
            </Typography>
          </Box>

          {/* Availability */}
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              fontSize: 16,
              color: product.available_products > 0 ? "green" : "red",
              mb: 2,
            }}
          >
            Availability: {product.available_products} items
          </Typography>

          {/* Description Points */}
          <DescriptionList>
            {Array.isArray(JSON.parse(product.description_point)) &&
              JSON.parse(product.description_point).map((point, index) => (
                <li key={index}>✔️ {point}</li>
              ))}
          </DescriptionList>

          {/* Item Type and Expiry Date */}
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            Type: {product.item_type}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            Expire Date: {dayjs(product.finish_date).format("DD/MM/YYYY")}
          </Typography>

          {/* Price Table */}
          <StyledTable component={Paper}>
            <Table aria-label="price table">
              <TableHead>
                <TableRow>
                  <TableCell>Duration</TableCell>
                  <TableCell align="right">Price (USD)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>30 days</TableCell>
                  <TableCell align="right">${product.price}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </StyledTable>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          my: 3,
          px: 2,
        }}
      >
        <StyledSlider
          value={duration}
          onChange={handleSliderChange}
          min={1}
          max={maxDuration}
          valueLabelDisplay="auto"
          sx={{ width: { xs: "100%", lg: "70%" } }}
        />
        <Box
          sx={{
            my: 3,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{ textAlign: "center", fontWeight: 700, fontSize: 16 }}
          >
            <strong>Start Date:</strong> {startDate}
          </Typography>
          <FormControl sx={{ my: 3, mx: 5 }}>
            <TextField
              label="Months"
              value={duration}
              onChange={handleInputChange}
              type="number"
              inputProps={{ min: 1, max: maxDuration }}
              sx={{ textAlign: "center" }}
            />
          </FormControl>
          <Typography
            variant="body2"
            sx={{ textAlign: "center", fontWeight: 700, fontSize: 16 }}
          >
            <strong>Finish Date:</strong> {finishDate}
          </Typography>
        </Box>
        <StyledButton
          variant="contained"
          onClick={handleAddToCart}
          disabled={product.available_products <= 0}
          sx={{ width: { xs: "100%", lg: "30%" }, paddingY: 2 }}
        >
          <ShoppingCartIcon sx={{ mr: 1 }} />
          Add to Cart
        </StyledButton>
      </Box>

      <Box
        sx={{
          mt: 2,
          mb: 3,
          p: 4,
          bgcolor: "gray.100",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Price Summary
        </Typography>
        <Typography variant="body1">
          {duration} month(s) : ${calculatePrice()}
        </Typography>
      </Box>

      {similarProducts.length > 0 && (
        <Typography
          variant="h4"
          sx={{ mt: 10, mb: 4, textAlign: { xs: "center", lg: "left" } }}
        >
          Similar Products
        </Typography>
      )}
      {similarProducts.length > 0 && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(2, 1fr)",
              sm: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
            },
            gap: 4,
            mt: 8,
          }}
        >
          {similarProducts.map((similarProduct) => (
            <Link
              key={similarProduct.item_id}
              to={`/shopping/${similarProduct.item_id}`}
              style={{ textDecoration: "none" }}
            >
              <Box
                sx={{
                  position: "relative",
                  bgcolor: "white",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                  cursor: "pointer",
                  transition: "transform 0.3s",
                  "&:hover": { transform: "scale(1.05)" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: 240,
                }}
              >
                {similarProduct.available_products <= 0 && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 10,
                      right: -25,
                      bgcolor: "red",
                      color: "white",
                      px: 2,
                      py: 1,
                      fontSize: "12px",
                      fontWeight: "bold",
                      transform:
                        "rotate(45deg) translateX(4px) translateY(-4px)",
                    }}
                  >
                    Out of Stock
                  </Box>
                )}
                <img
                  src={similarProduct.product_image}
                  alt={similarProduct.item_name}
                  style={{ height: "75%", width: "100%", objectFit: "cover" }}
                />
                <Box sx={{ textAlign: "center", p: 2 }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {similarProduct.item_name}
                  </Typography>
                </Box>
              </Box>
            </Link>
          ))}
        </Box>
      )}
    </StyledContainer>
  );
};

export default Product;
