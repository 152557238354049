// File: CreateUser.js
import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { registerUser } from "../../../utills/utills";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/UserContext";

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  width: "100%",
  borderRadius: "8px",
});

const CreateUser = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userRole, setUserRole] = useState("user");
  const [mobile, setMobile] = useState("");
  const [credit, setCredit] = useState();
  const [confirmPassword, setConfirmPassword] = useState("");
  const { user } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const validateForm = () => {
    // Username validation
    if (username.trim().length < 3) {
      enqueueSnackbar("Username must be at least 3 characters long", {
        variant: "warning",
      });
      return false;
    }
    // Mobile validation
    if (!/^\d{8,}$/.test(mobile)) {
      enqueueSnackbar("Mobile number must be at least 8 digits", {
        variant: "warning",
      });
      return false;
    }
    // Email validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      enqueueSnackbar("Invalid email address", { variant: "warning" });
      return false;
    }
    // Password validation
    if (password.length < 6) {
      enqueueSnackbar("Password must be at least 6 characters long", {
        variant: "warning",
      });
      return false;
    }
    // Confirm password validation
    if (password !== confirmPassword) {
      enqueueSnackbar("Passwords do not match", { variant: "warning" });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      await registerUser(
        username,
        email,
        password,
        mobile,
        null, // Ensure fingerprint is sent as null
        userRole,
        credit
      );
      enqueueSnackbar("User created successfully", { variant: "success" });
      window.location.reload();
      navigate("/");
    } catch (error) {
      console.log("Error creating user:", error);
      enqueueSnackbar("Error creating user", { variant: "error" });
    }
  };

  return (
    user && (
      <FormContainer>
        <Typography variant="h4" component="h1" gutterBottom>
          Create User
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            sx={{ justifyContent: "space-" }}
          >
            <label className="font-bold text-lg px-20 w-1/3">User Name</label>
            <TextField
              label="Username"
              name="username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              margin="normal"
              sx={{ width: "60%" }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            sx={{ justifyContent: "space-" }}
          >
            <label className="font-bold text-lg px-20 w-1/3">
              Mobile Number
            </label>
            <TextField
              label="Mobile Number"
              name="mobile"
              variant="outlined"
              fullWidth
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              margin="normal"
              sx={{ width: "60%" }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            sx={{ justifyContent: "space-" }}
          >
            <label className="font-bold text-lg px-20 w-1/3">Email</label>
            <TextField
              label="Email"
              name="email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              sx={{ width: "60%" }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            sx={{ justifyContent: "space-" }}
          >
            <label className="font-bold text-lg px-20 w-1/3">Password</label>
            <TextField
              label="Password"
              name="password"
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              sx={{ width: "60%" }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            sx={{ justifyContent: "space-" }}
          >
            <label className="font-bold text-lg px-20 w-1/3">
              Confirm Password
            </label>
            <TextField
              label="Confirm Password"
              name="confirm_password"
              type="password"
              variant="outlined"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              margin="normal"
              sx={{ width: "60%" }}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            mb={2}
            sx={{ justifyContent: "center" }}
          >
            <RadioGroup
              name="user_role"
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
              row
            >
              <FormControlLabel value="user" control={<Radio />} label="User" />
              {user.user_role === "superadmin" && (
                <>
                  <FormControlLabel
                    value="admin"
                    control={<Radio />}
                    label="Admin"
                  />
                  <FormControlLabel
                    value="superadmin"
                    control={<Radio />}
                    label="Super Admin"
                  />
                </>
              )}
            </RadioGroup>
          </Box>

          {userRole === "admin" ? (
            <Box
              display="flex"
              alignItems="center"
              mb={2}
              sx={{ justifyContent: "space-" }}
            >
              <label className="font-bold text-lg px-20 w-1/3">
                Credit Balance
              </label>
              <TextField
                label="Credit Balance"
                name="credit"
                variant="outlined"
                fullWidth
                value={credit}
                onChange={(e) => setCredit(e.target.value)}
                margin="normal"
                sx={{ width: "60%" }}
                type="number"
              />
            </Box>
          ) : null}
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            sx={{ justifyContent: "center" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: "16px", width: "40%" }}
            >
              Create User
            </Button>
          </Box>
        </form>
      </FormContainer>
    )
  );
};

export default CreateUser;
