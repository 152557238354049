// File: EditUser.js
import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/UserContext";
import { updateUser } from "../../../utills/utills";
import { useSnackbar } from "notistack";

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  width: "100%",
  borderRadius: "8px",
});

const EditUser = () => {
  const { id } = useParams();
  const { userList, user } = useAuth();
  const [userEdit, setUserEdit] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const userToEdit = userList.find((u) => u.user_id === parseInt(id));
    if (userToEdit) {
      setUserEdit(userToEdit);
    }
  }, [id, userList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserEdit((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      enqueueSnackbar("Passwords do not match", { variant: "warning" });
      return;
    }
    const updatedUser = { ...userEdit };
    if (password) {
      updatedUser.password = password;
    }
    try {
      await updateUser(userEdit.user_id, updatedUser);
      enqueueSnackbar("User updated successfully", { variant: "success" });
      window.location.reload();
      navigate("/");
    } catch (error) {
      console.log("Error updating user:", error);
      enqueueSnackbar("Error updating user", { variant: "error" });
    }
  };

  if (!userEdit) {
    return <div>Loading...</div>;
  }

  return (
    <FormContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        Edit User
      </Typography>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-" }}
        >
          <label className="font-bold text-lg px-20 w-1/3">Username</label>
          <TextField
            label="Username"
            name="username"
            variant="outlined"
            value={userEdit.username}
            onChange={handleChange}
            margin="normal"
            sx={{ width: "60%" }}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-" }}
        >
          <label className="font-bold text-lg px-20 w-1/3">Email</label>
          <TextField
            label="Email"
            name="email"
            variant="outlined"
            fullWidth
            value={userEdit.email}
            onChange={handleChange}
            margin="normal"
            sx={{ width: "60%" }}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-" }}
        >
          <label className="font-bold text-lg px-20 w-1/3">Mobile no</label>
          <TextField
            label="Mobile no"
            name="mobile"
            variant="outlined"
            fullWidth
            value={userEdit.mobile}
            onChange={handleChange}
            margin="normal"
            sx={{ width: "60%" }}
          />
        </Box>
        {user.user_role !== "user" && (
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            sx={{ justifyContent: "space-" }}
          >
            <label className="font-bold text-lg px-20 w-1/3">Device</label>
            <TextField
              label="Device"
              name="fingerprint"
              variant="outlined"
              fullWidth
              value={userEdit.fingerprint}
              onChange={handleChange}
              margin="normal"
              sx={{ width: "60%" }}
            />
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-" }}
        >
          <label className="font-bold text-lg px-20 w-1/3">New Password</label>
          <TextField
            label="New Password"
            name="password"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            margin="normal"
            sx={{ width: "60%" }}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-" }}
        >
          <label className="font-bold text-lg px-20 w-1/3">
            Confirm Password
          </label>
          <TextField
            label="Confirm Password"
            name="confirm_password"
            type="password"
            variant="outlined"
            fullWidth
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            margin="normal"
            sx={{ width: "60%" }}
          />
        </Box>
        {user.user_role === "superadmin" && userEdit.user_role === "admin" && (
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            sx={{ justifyContent: "space-" }}
          >
            <label className="font-bold text-lg px-20 w-1/3">
              Credit Balance
            </label>
            <TextField
              label="Credit Balance"
              name="credit"
              variant="outlined"
              fullWidth
              value={userEdit.credit}
              onChange={handleChange}
              margin="normal"
              sx={{ width: "60%" }}
              type="number"
            />
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "center" }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "16px", width: "40%" }}
          >
            Save User
          </Button>
        </Box>
      </form>
    </FormContainer>
  );
};

export default EditUser;
