import React, { useState } from "react";
import {
  TextField,
  Button,
  Input,
  IconButton,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  Box,
} from "@mui/material";
import { CloudUpload, AccountBox, Person, Lock } from "@mui/icons-material";
import { styled } from "@mui/system";
import { createItem } from "../../../utills/utills";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  width: "100%",
  // maxWidth: "75%",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
});

const UploadContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "16px",
});

const ImagePreview = styled("img")({
  width: "100%",
  maxHeight: "300px",
  objectFit: "cover",
  marginTop: "8px",
  borderRadius: "4px",
});

const CreateItem = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState("");
  const [imageSource, setImageSource] = useState("file");

  const formik = useFormik({
    initialValues: {
      item_name: "",
      product_image: "",
      available_products: "",
      price: "",
      eseller_price: "",
      description: "",
      description_point: [],
      start_date: "",
      finish_date: "",
      item_email: "",
      item_password: "",
      item_type: "",
      item_url: "",
    },
    validationSchema: Yup.object({
      item_name: Yup.string().required("Item Name is required"),
      available_products: Yup.number()
        .required("Available Products is required")
        .min(1, "Must be at least 1"),
      price: Yup.number()
        .required("Price is required")
        .positive("Must be positive"),
      eseller_price: Yup.number()
        .required("E-Seller Price is required")
        .positive("Must be positive"),
      description: Yup.string().required("Description is required"),
      item_email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      item_password: Yup.string().required("Password is required"),
      start_date: Yup.date().required("Start Date is required"),
      finish_date: Yup.date()
        .required("Finish Date is required")
        .min(Yup.ref("start_date"), "Finish Date must be after Start Date"),
      item_url: Yup.string()
        .url("Invalid URL format")
        .required("Item URL is required"),
      item_type: Yup.string().required("Package Type is required"),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      const descriptionPointStr = values.description_point.join(",");

      Object.keys(values).forEach((key) => {
        if (key === "product_image" && imageSource === "file") {
          formData.append(key, values[key]);
        } else if (key === "description_point") {
          formData.append(key, descriptionPointStr);
        } else {
          formData.append(key, values[key]);
        }
      });

      await createItem(formData);
      enqueueSnackbar("Item created successfully", { variant: "success" });
      window.location.reload();
      navigate("/");
    },
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      formik.setFieldValue("product_image", file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleImageUrlChange = (e) => {
    const { value } = e.target;
    formik.setFieldValue("product_image", value);
    setImagePreview(value);
  };

  const handleAddPoints = () => {
    formik.setFieldValue("description_point", [
      ...formik.values.description_point,
      "",
    ]);
  };

  const handleDescriptionPointChange = (index, value) => {
    const updatedPoints = [...formik.values.description_point];
    updatedPoints[index] = value;
    formik.setFieldValue("description_point", updatedPoints);
  };

  return (
    <FormContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        Create New Item
      </Typography>
      <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <UploadContainer>
          <label htmlFor="upload-image">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              style={{ fontSize: "48px" }}
            >
              <CloudUpload fontSize="inherit" />
            </IconButton>
          </label>
          <RadioGroup
            row
            name="imageSource"
            value={imageSource}
            onChange={(e) => setImageSource(e.target.value)}
          >
            <FormControlLabel
              value="file"
              control={<Radio />}
              label="Upload File"
            />
            <FormControlLabel
              value="url"
              control={<Radio />}
              label="Image URL"
            />
          </RadioGroup>
          {imageSource === "file" ? (
            <Input
              id="upload-image"
              type="file"
              inputProps={{ accept: "image/*" }}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          ) : (
            <TextField
              sx={{ width: "60%" }}
              label="Image URL"
              name="product_image"
              variant="outlined"
              value={formik.values.product_image}
              onChange={handleImageUrlChange}
              margin="normal"
              error={
                formik.touched.product_image &&
                Boolean(formik.errors.product_image)
              }
              helperText={
                formik.touched.product_image && formik.errors.product_image
              }
            />
          )}
          {imagePreview && (
            <ImagePreview src={imagePreview} alt="Product Preview" />
          )}
        </UploadContainer>

        {/* Item Name */}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-between" }}
        >
          <label className="font-bold text-lg px-20">Item Name</label>
          <TextField
            label="Item Name"
            name="item_name"
            variant="outlined"
            value={formik.values.item_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            sx={{ width: "60%" }}
            error={formik.touched.item_name && Boolean(formik.errors.item_name)}
            helperText={formik.touched.item_name && formik.errors.item_name}
          />
        </Box>

        {/* Available Products */}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-between" }}
        >
          <label className="font-bold text-lg px-20">Available Products</label>
          <TextField
            sx={{ width: "60%" }}
            label="Available Products"
            name="available_products"
            type="number"
            variant="outlined"
            value={formik.values.available_products}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.available_products &&
              Boolean(formik.errors.available_products)
            }
            helperText={
              formik.touched.available_products &&
              formik.errors.available_products
            }
          />
        </Box>

        {/* Price */}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-between" }}
        >
          <label className="font-bold text-lg px-20">Price</label>
          <TextField
            sx={{ width: "60%" }}
            label="Price"
            name="price"
            type="number"
            variant="outlined"
            value={formik.values.price}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Box>

        {/* E-Seller Price */}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-between" }}
        >
          <label className="font-bold text-lg px-20">E-Seller Price</label>
          <TextField
            sx={{ width: "60%" }}
            label="E-Seller Price"
            name="eseller_price"
            type="number"
            variant="outlined"
            value={formik.values.eseller_price}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.eseller_price &&
              Boolean(formik.errors.eseller_price)
            }
            helperText={
              formik.touched.eseller_price && formik.errors.eseller_price
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Box>

        {/* Description */}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-between" }}
        >
          <label className="font-bold text-lg px-20">Description</label>
          <TextField
            sx={{ width: "60%" }}
            label="Description"
            name="description"
            variant="outlined"
            multiline
            rows={4}
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
        </Box>

        {/* Add Description Points */}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-between" }}
        >
          <Button
            onClick={handleAddPoints}
            variant="contained"
            color="secondary"
            width="50%"
            sx={{
              backgroundColor: "#4CAF50",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#3e8e41",
              },
              marginLeft: "auto",
            }}
          >
            Add Description Point
          </Button>
        </Box>

        {formik.values.description_point.map((point, index) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            key={index}
            mb={2}
          >
            <label className="font-bold text-lg px-20">
              Description Point {index + 1}
            </label>
            <TextField
              sx={{ width: "60%" }}
              label="Description Points"
              variant="outlined"
              value={point}
              onChange={(e) =>
                handleDescriptionPointChange(index, e.target.value)
              }
            />
          </Box>
        ))}

        {/* Start Date */}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-between" }}
        >
          <label className="font-bold text-lg px-20">Start Date</label>
          <TextField
            sx={{ width: "60%" }}
            name="start_date"
            type="date"
            variant="outlined"
            value={formik.values.start_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.start_date && Boolean(formik.errors.start_date)
            }
            helperText={formik.touched.start_date && formik.errors.start_date}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        {/* Finish Date */}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-between" }}
        >
          <label className="font-bold text-lg px-20">Finish Date</label>
          <TextField
            sx={{ width: "60%" }}
            name="finish_date"
            type="date"
            variant="outlined"
            value={formik.values.finish_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.finish_date && Boolean(formik.errors.finish_date)
            }
            helperText={formik.touched.finish_date && formik.errors.finish_date}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        {/* Package Types */}
        <Box
          mb={2}
          sx={{
            display: "flex",
            width: "90%",
            justifyContent: "space-between",
          }}
        >
          <label className="font-bold text-lg px-20">Package Types</label>
          <RadioGroup
            name="item_type"
            value={formik.values.item_type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.item_type && Boolean(formik.errors.item_type)}
          >
            <FormControlLabel
              value="direct/shared"
              control={<Radio />}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Person style={{ marginRight: "8px" }} />
                  Direct/Shared
                </div>
              }
            />
            <FormControlLabel
              value="direct/private"
              control={<Radio />}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Lock style={{ marginRight: "8px" }} />
                  Direct/Private
                </div>
              }
            />
            <FormControlLabel
              value="own account upgrade"
              control={<Radio />}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AccountBox style={{ marginRight: "8px" }} />
                  Own Account Upgrade
                </div>
              }
            />
          </RadioGroup>
        </Box>

        {/* Item URL */}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-between" }}
        >
          <label className="font-bold text-lg px-20">Item URL</label>
          <TextField
            sx={{ width: "60%" }}
            label="Item URL"
            name="item_url"
            variant="outlined"
            value={formik.values.item_url}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.item_url && Boolean(formik.errors.item_url)}
            helperText={formik.touched.item_url && formik.errors.item_url}
          />
        </Box>

        {/* Package Email */}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-between" }}
        >
          <label className="font-bold text-lg px-20">Package Email</label>
          <TextField
            sx={{ width: "60%" }}
            label="Package Email"
            name="item_email"
            variant="outlined"
            value={formik.values.item_email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.item_email && Boolean(formik.errors.item_email)
            }
            helperText={formik.touched.item_email && formik.errors.item_email}
          />
        </Box>

        {/* Package Password */}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ justifyContent: "space-between" }}
        >
          <label className="font-bold text-lg px-20">Package Password</label>
          <TextField
            sx={{ width: "60%" }}
            label="Package Password"
            name="item_password"
            type="password"
            variant="outlined"
            value={formik.values.item_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.item_password &&
              Boolean(formik.errors.item_password)
            }
            helperText={
              formik.touched.item_password && formik.errors.item_password
            }
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "90%",
            justifyContent: "center",
          }}
          display="flex"
          alignItems="center"
          mb={2}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: "16px", paddingHorizontal: 10, width: "30%" }}
          >
            Save Product
          </Button>
        </Box>
      </form>
    </FormContainer>
  );
};

export default CreateItem;
