import axios from "axios";

export const baseUrl = process.env.REACT_APP_BACKEND_URL;

// User
// Login
export const loginUser = async (email, password, fingerprint) => {
  try {
    const response = await axios.post(`${baseUrl}/api/users/login`, {
      email,
      password,
      fingerprint,
    });
    return response.data;
  } catch (error) {
    console.error("Error logging in:", error.response.data.message);
    throw error.response.data;
  }
};

// Register / Create User
export const registerUser = async (
  username,
  email,
  password,
  mobile,
  fingerprint,
  user_role,
  credit
) => {
  try {
    const response = await axios.post(`${baseUrl}/api/users`, {
      username,
      email,
      password,
      mobile,
      fingerprint,
      user_role,
      credit,
    });
    return response.data;
  } catch (error) {
    console.error("Error registering:", error.response.data.message);
    throw error.response.data;
  }
};

// Get All Users
export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/users`);
    return response.data;
  } catch (error) {
    console.error("Error getting all users:", error.response.data.message);
    throw error.response.data;
  }
};

// Get a Single User by ID
export const getUserById = async (userId) => {
  try {
    const response = await axios.get(`${baseUrl}/api/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error getting user by ID:", error.response.data.message);
    throw error.response.data;
  }
};

// Get a Single User by Email
export const getUserByEmail = async (email) => {
  try {
    const response = await axios.post(`${baseUrl}/api/users/getbyemail`, {
      email,
    });
    return response.data;
  } catch (error) {
    console.error("Error getting user by email:", error.response.data.message);
    throw error.response.data;
  }
};

// Update User
export const updateUser = async (userId, updatedFields) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/users/${userId}`,
      updatedFields
    );
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error.response.data.message);
    throw error.response.data;
  }
};

export const requestOTP = async (email) => {
  const response = await axios.post(`${baseUrl}/api/users/request-otp`, {
    email,
  });
  return response.data;
};

export const verifyOTP = async (email, otp, newPassword) => {
  const response = await axios.post(`${baseUrl}/api/users/verify-otp`, {
    email,
    otp,
    newPassword,
  });
  return response.data;
};
// Delete User
export const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`${baseUrl}/api/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error.response.data.message);
    throw error.response.data;
  }
};

// Items and Products
// Get All Items
export const getAllItems = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/items`);
    return response.data;
  } catch (error) {
    console.error("Error getting all items:", error.response.data.message);
    throw error.response.data;
  }
};

// Get All Products
export const getAllProducts = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/items/products`);
    return response.data;
  } catch (error) {
    console.error("Error getting all products:", error.response.data.message);
    throw error.response.data;
  }
};

// Get Item by ID
export const getItemById = async (itemId) => {
  try {
    const response = await axios.get(`${baseUrl}/api/items/${itemId}`);
    return response.data;
  } catch (error) {
    console.error("Error getting item by ID:", error.response.data.message);
    throw error.response.data;
  }
};

// Create Item
export const createItem = async (itemData) => {
  try {
    const response = await axios.post(`${baseUrl}/api/items`, itemData);
    return response.data;
  } catch (error) {
    console.error("Error creating item:", error.response.data.message);
    throw error.response.data;
  }
};

// Update Item
export const updateItem = async (itemId, updatedFields) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/items/${itemId}`,
      updatedFields
    );
    return response.data;
  } catch (error) {
    console.error("Error updating item:", error.response.data.message);
    throw error.response.data;
  }
};

// Delete item
export const deleteItem = async (itemId) => {
  try {
    const response = await axios.delete(`${baseUrl}/api/items/${itemId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting item:",
      error.response?.data?.message || error.message
    );
    throw error.response?.data || error;
  }
};

// Delete product
export const deleteProduct = async (productId) => {
  try {
    const response = await axios.delete(`${baseUrl}/api/products/${productId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting product:", error.response.data.message);
    throw error.response.data;
  }
};

// Get Products by Item ID
export const getProductsByItemId = async (itemId) => {
  try {
    const response = await axios.get(`${baseUrl}/api/products/${itemId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error getting products by item ID:",
      error.response.data.message
    );
    throw error.response.data;
  }
};

// Update Product Credentials (email and password)
export const updateProductCredentials = async (productId, updatedFields) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/products/${productId}`,
      updatedFields
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error updating product credentials:",
      error.response.data.message
    );
    throw error.response.data;
  }
};

// Cart and Purchase
// Add Product to Cart
export const addProductToCart = async (
  userId,
  productId,
  package_start_date,
  package_expire_date,
  price
) => {
  try {
    const response = await axios.post(`${baseUrl}/api/cart`, {
      user_id: userId,
      product_id: productId,
      package_start_date: package_start_date,
      package_expire_date: package_expire_date,
      price: price,
    });
    return response.data;
  } catch (error) {
    console.error("Error adding product to cart:", error.response.data.message);
    throw error.response.data;
  }
};

// Get Cart Products
export const getCartProducts = async (userId) => {
  try {
    const response = await axios.get(`${baseUrl}/api/cart/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error getting cart products:", error.response.data.message);
    throw error.response.data;
  }
};
// Remove product from cart
export const removeProductFromCart = async (cartId) => {
  try {
    const response = await axios.delete(`${baseUrl}/api/cart/${cartId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error removing product from cart:",
      error.response.data.message
    );
    throw error.response.data;
  }
};

// Complete Purchase
export const completePurchase = async (
  userId,
  productIds,
  receiptImage,
  status
) => {
  try {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("product_ids", JSON.stringify(productIds));
    if (receiptImage) {
      formData.append("payment_reciept", receiptImage);
    }
    formData.append("status", status);

    const response = await axios.post(
      `${baseUrl}/api/cart/complete-purchase`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error completing purchase:", error.response.data.message);
    throw error.response.data;
  }
};

// Get All Purchases
export const getPurchases = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/purchases`);
    return response.data;
  } catch (error) {
    console.error("Error getting purchases:", error.response.data.message);
    throw error.response.data;
  }
};

// Accept Purchase
export const acceptPurchase = async (purchaseId) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/purchases/${purchaseId}/accept`
    );
    return response.data;
  } catch (error) {
    console.error("Error accepting purchase:", error.response.data.message);
    throw error.response.data;
  }
};

// Reject Purchase
export const rejectPurchase = async (purchaseId) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/purchases/${purchaseId}/reject`
    );
    return response.data;
  } catch (error) {
    console.error("Error rejecting purchase:", error.response.data.message);
    throw error.response.data;
  }
};

// add product to user
export const addProductToPurchase = async (userId, productId, adminId) => {
  const response = await axios.post(`${baseUrl}/api/purchases`, {
    user_id: userId,
    product_id: productId,
    admin_id: adminId,
  });
  return response.data;
};

// delete a purchesed item from user
export const deletePurchase = async (purchaseId) => {
  try {
    const response = await axios.delete(
      `${baseUrl}/api/purchases/${purchaseId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting purchase:", error.response.data.message);
    throw error.response.data;
  }
};

// Update Purchase Dates
export const updatePurchaseDates = async (purchaseId, updatedFields) => {
  try {
    const response = await axios.put(
      `${baseUrl}/api/purchases/${purchaseId}/dates`,
      updatedFields
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error updating purchase dates:",
      error.response.data.message
    );
    throw error.response.data;
  }
};

// email send
export const sendEmailToAdmin = async (email, subject, message) => {
  const response = await axios.post(`${baseUrl}/api/send-email`, {
    email,
    subject,
    message,
  });
  return response.data;
};

// payment
export const createPaymentIntent = async (amount) => {
  try {
    const response = await axios.post(`${baseUrl}/api/create-payment-intent`, {
      amount,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating payment intent:", error);
    throw error;
  }
};
