import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Input,
  IconButton,
  InputLabel,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  Box,
} from "@mui/material";
import { CloudUpload, AccountBox, Person, Lock } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { useItemContext } from "../../../context/ItemsContext";
import { updateItem } from "../../../utills/utills";
import { useSnackbar } from "notistack";


// Convert ISO 8601 date to MySQL format (YYYY-MM-DD HH:MM:SS)
const formatDateForMySQL = (isoDate) => {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};


const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  width: "100%",
  maxWidth: "100%",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
});

const UploadContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "16px",
});

const ImagePreview = styled("img")({
  // width: "10%",
  maxHeight: "300px",
  objectFit: "cover",
  marginTop: "8px",
  borderRadius: "4px",
});

const EditProduct = () => {
  const { id } = useParams();
  const { allItems } = useItemContext();
  const [originalProduct, setOriginalProduct] = useState(null);
  const [product, setProduct] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [imageSource, setImageSource] = useState("file");

  console.log(originalProduct);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (allItems) {
      const productToEdit = allItems.find((p) => p.item_id === parseInt(id));
      if (productToEdit) {
        setOriginalProduct(productToEdit);
        setProduct({
          ...productToEdit,
          description_point: productToEdit.description_point
            ? JSON.parse(productToEdit.description_point)
            : [],
        });
        setImagePreview(productToEdit.product_image);
      }
    }
  }, [id, allItems]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProduct((prevProduct) => ({
        ...prevProduct,
        product_image: imageUrl,
      }));
      setImagePreview(imageUrl);
    }
  };

  const handleImageUrlChange = (e) => {
    const { value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      product_image: value,
    }));
    setImagePreview(value);
  };

  const handleAddPoints = () => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      description_point: [...prevProduct.description_point, ""],
    }));
  };

  const handleDescriptionPointChange = (index, value) => {
    const updatedPoints = [...product.description_point];
    updatedPoints[index] = value;
    setProduct((prevProduct) => ({
      ...prevProduct,
      description_point: updatedPoints,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedFields = {
      ...product,
      description_point: JSON.stringify(product.description_point),
      start_date: formatDateForMySQL(product.start_date),
      finish_date: formatDateForMySQL(product.finish_date),
    };

    try {
      await updateItem(id, updatedFields);
      enqueueSnackbar("Item edited successfully", { variant: "success" });
      navigate("/");
    } catch (error) {
      enqueueSnackbar("Error editing item", { variant: "error" });
      console.log(error);
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <FormContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        Edit Product
      </Typography>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <UploadContainer>
          <InputLabel htmlFor="upload-image">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              style={{ fontSize: "48px" }}
            >
              <CloudUpload fontSize="inherit" />
            </IconButton>
          </InputLabel>
          <RadioGroup
            row
            name="imageSource"
            value={imageSource}
            onChange={(e) => setImageSource(e.target.value)}
          >
            <FormControlLabel
              value="file"
              control={<Radio />}
              label="Upload File"
            />
            <FormControlLabel
              value="url"
              control={<Radio />}
              label="Image URL"
            />
          </RadioGroup>
          {imageSource === "file" ? (
            <Input
              id="upload-image"
              type="file"
              inputProps={{ accept: "image/*" }}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          ) : (
            <TextField
              label="Image URL"
              name="product_image"
              variant="outlined"
              fullWidth
              value={product.product_image}
              onChange={handleImageUrlChange}
              margin="normal"
            />
          )}
          {imagePreview && (
            <ImagePreview src={imagePreview} alt="Product Preview" />
          )}
        </UploadContainer>

        <Box
          display="flex"
          alignItems="center"
          sx={{ justifyContent: "space-between" }}
          mb={2}
        >
          <label className="font-bold text-lg px-20">Item Name</label>
          <TextField
            label="Item Name"
            name="item_name"
            variant="outlined"
            fullWidth
            value={product.item_name}
            onChange={handleChange}
            margin="normal"
            sx={{ width: "60%" }}
          />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          sx={{ justifyContent: "space-between" }}
          mb={2}
        >
          <label className="font-bold text-lg px-20">Available Products</label>
          <TextField
            label="Available Products"
            name="available_products"
            type="number"
            variant="outlined"
            fullWidth
            value={product.available_products}
            onChange={handleChange}
            margin="normal"
            sx={{ width: "60%" }}
          />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          sx={{ justifyContent: "space-between" }}
          mb={2}
        >
          <label className="font-bold text-lg px-20">Price</label>
          <TextField
            label="Price"
            name="price"
            type="number"
            variant="outlined"
            fullWidth
            value={product.price}
            onChange={handleChange}
            margin="normal"
            sx={{ width: "60%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          sx={{ justifyContent: "space-between" }}
          mb={2}
        >
          <label className="font-bold text-lg px-20">E-Seller Price</label>
          <TextField
            label="E-Seller Price"
            name="eseller_price"
            type="number"
            variant="outlined"
            fullWidth
            value={product.eseller_price}
            onChange={handleChange}
            margin="normal"
            sx={{ width: "60%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          sx={{ justifyContent: "space-between" }}
          mb={2}
        >
          <label className="font-bold text-lg px-20">Description</label>
          <TextField
            label="Description"
            name="description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={product.description}
            onChange={handleChange}
            margin="normal"
            sx={{ width: "60%" }}
          />
        </Box>

        {/* Dynamic Description Points */}
        <Box
          display="flex"
          alignItems="center"
          sx={{ justifyContent: "space-between" }}
          mb={2}
        >
          <Button
            onClick={handleAddPoints}
            variant="contained"
            color="secondary"
            sx={{
              backgroundColor: "#4CAF50",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#3e8e41",
              },
              marginLeft: "auto",
            }}
          >
            Add Description Point
          </Button>
        </Box>

        {product.description_point.map((point, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            mb={2}
            sx={{ justifyContent: "space-between" }}
          >
            <label className="font-bold text-lg px-20">
              Description Point {index + 1}
            </label>
            <TextField
              label={`Description Point ${index + 1}`}
              fullWidth
              variant="outlined"
              value={point}
              onChange={(e) =>
                handleDescriptionPointChange(index, e.target.value)
              }
              sx={{ width: "60%" }}
            />
          </Box>
        ))}

        <Box
          display="flex"
          alignItems="center"
          sx={{ justifyContent: "space-between" }}
          mb={2}
        >
          <label className="font-bold text-lg px-20">Start Date</label>
          <TextField
            label="Start Date"
            name="start_date"
            type="date"
            variant="outlined"
            fullWidth
            value={product.start_date}
            onChange={handleChange}
            margin="normal"
            sx={{ width: "60%" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          sx={{ justifyContent: "space-between" }}
          mb={2}
        >
          <label className="font-bold text-lg px-20">Finish Date</label>
          <TextField
            label="Finish Date"
            name="finish_date"
            type="date"
            variant="outlined"
            fullWidth
            value={product.finish_date}
            onChange={handleChange}
            margin="normal"
            sx={{ width: "60%" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        {/* Package Types */}
        <Box
          mb={2}
          sx={{
            display: "flex",
            width: "90%",
            justifyContent: "space-between",
          }}
        >
          <label className="font-bold text-lg px-20">Package Types</label>
          <RadioGroup
            name="item_type"
            value={product.item_type}
            onChange={handleChange}
            sx={{ width: "60%" }}
          >
            <FormControlLabel
              value="direct/shared"
              control={<Radio />}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Person style={{ marginRight: "8px" }} />
                  Direct/Shared
                </div>
              }
            />
            <FormControlLabel
              value="direct/private"
              control={<Radio />}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Lock style={{ marginRight: "8px" }} />
                  Direct/Private
                </div>
              }
            />
            <FormControlLabel
              value="own account upgrade"
              control={<Radio />}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AccountBox style={{ marginRight: "8px" }} />
                  Own Account Upgrade
                </div>
              }
            />
          </RadioGroup>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          sx={{ justifyContent: "space-between" }}
          mb={2}
        >
          <label className="font-bold text-lg px-20">Item URL</label>
          <TextField
            label="Item URL"
            name="item_url"
            variant="outlined"
            fullWidth
            value={product.item_url}
            onChange={handleChange}
            margin="normal"
            sx={{ width: "60%" }}
          />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          sx={{ justifyContent: "space-between" }}
          mb={2}
        >
          <label className="font-bold text-lg px-20">Package Email</label>
          <TextField
            label="Package Email"
            name="item_email"
            variant="outlined"
            fullWidth
            value={product.item_email}
            onChange={handleChange}
            margin="normal"
            sx={{ width: "60%" }}
          />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          sx={{ justifyContent: "space-between" }}
          mb={2}
        >
          <label className="font-bold text-lg px-20">Package Password</label>
          <TextField
            label="Package Password"
            name="item_password"
            type="password"
            variant="outlined"
            fullWidth
            value={product.item_password}
            onChange={handleChange}
            margin="normal"
            sx={{ width: "60%" }}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: "16px", paddingHorizontal: 10, width: "30%" }}
        >
          Save Product
        </Button>
      </form>
    </FormContainer>
  );
};

export default EditProduct;
