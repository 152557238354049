import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import Profile from "../pages/profile/Profile";
import Shop from "../pages/shopping/Shop";
import Cart from "../pages/cart/Cart";
import Product from "../pages/shopping/Product";
import Orders from "../pages/dashboard/AdminComponents/Orders";
import Users from "../pages/dashboard/AdminComponents/Users";
import CreateItem from "../pages/dashboard/AdminComponents/CreateItem";
import CreateUser from "../pages/dashboard/AdminComponents/CreateUser";
import EditUser from "../pages/dashboard/AdminComponents/EditUser";
import EditProduct from "../pages/dashboard/AdminComponents/EditProduct";
import UserProfile from "../pages/dashboard/AdminComponents/UserProfile";
import Products from "../pages/dashboard/AdminComponents/Products";
import PaymentPage from "../pages/payment/PaymentPage";


const NavigationLayout = () => {
  return (
    <div className="w-full bg-blue-100 ">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/shopping" element={<Shop />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/shopping/:id" element={<Product />} />
        {/* Admin */}
        <Route path="/orders" element={<Orders />} />
        <Route path="/users" element={<Users />} />
        <Route path="/create-item" element={<CreateItem />} />
        <Route path="/create-user" element={<CreateUser />} />
        <Route path="/users/edit/:id" element={<EditUser />} />
        <Route path="/product/edit/:id" element={<EditProduct />} />
        <Route path="/users/profile/:user_id" element={<UserProfile />} />
        <Route path="/products" element={<Products />} />
        <Route path="/payment" element={<PaymentPage />} />
      </Routes>
    </div>
  );
};

export default NavigationLayout;
